




























import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import HeadMenuBox from './head-menu-box.vue'
import { getCountrySelect } from '@/api/school_api'
@Component({ 
  name: 'HeadMenuBoxWithCountry', 
  components: {
    HeadMenuBox
  }
})
export default class HeadMenuBoxWithCountry extends Vue {
  @Prop({required: false, type: String}) keyword!: string;
  @Prop() fkAreaCountryId?:any
  @Prop() createShow!: Boolean
  // 是否需要国家
  @Prop({ default: true }) isNeedCountry!: Boolean
  get _keyword(){
    return this.keyword;
  }
  get _fkAreaCountryId(){
    return this.fkAreaCountryId;
  }
  set _keyword(val: string){
    this.$emit('update:keyword',val)
  }
  set _fkAreaCountryId(val: any){
    this.$emit('update:fkAreaCountryId',val)
  }
  @Emit()
  search(){
    return [this.keyword,this.fkAreaCountryId]
  }
  mounted(){
    this.getCountrySelect();
  }
  countryOption: any[] = [];
  // 获取国家下拉框
  async getCountrySelect() {
    const { data } = await getCountrySelect();
    this.countryOption = data.data;
  }
}
