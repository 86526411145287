import { render, staticRenderFns } from "./head-menu-box.vue?vue&type=template&id=558ab048&scoped=true&"
import script from "./head-menu-box.vue?vue&type=script&lang=ts&"
export * from "./head-menu-box.vue?vue&type=script&lang=ts&"
import style0 from "./head-menu-box.vue?vue&type=style&index=0&id=558ab048&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "558ab048",
  null
  
)

export default component.exports