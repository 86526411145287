


















































import PaginationBox from '@/view/admin/components/pagination-box.vue'
import HeadMenuBoxWithCountry from '@/view/admin/components/head-menu-box-with-country.vue'
import AddSchool from './components/add_school.vue';
import tableList from '@/components/tableList.vue';

import {Vue, Component} from 'vue-property-decorator';
import { getSchoolListApi } from '@/api/school_api';
import { hasVal }  from '@/utils/index';
import { debounce } from '@/utils/delayed';
@Component({
  name: 'adminSchoolList', 
  components: {
    PaginationBox, 
    HeadMenuBoxWithCountry,
    AddSchool,
    tableList
  }
})
export default class AdminSchoolList extends Vue{
  schoolData: any[] = [];
  // public schoolData: any[] = [{institutionName: '1232141'}];
  // 分页
  reqFilterTable: any = { currentPage: 1, showCount: 10, totalResult: 0 };
  // 请求参数
  reqFilterData: any = {
    keyword: '',
    fkAreaCountryId:'',
    dataType: 0,
  }
  // 防抖
  debounceInit = debounce(this.getSchoolList, 100, true);
  // 查看
  handleCheck(row: any) {
    const guid = String(row.guid);
    const routeUrl = this.$router.resolve({name: 'schooolDetail', params: {id: guid}})
    window.open(routeUrl.href, '_blank')
  }
  get isPermission () {
    return (str: string) => {
      return this.$route.meta.permission.indexOf(str) >= 0;
    }
  }
  created() {
    this.debounceInit();
  }
  // 获取学校列表
  getSchoolList() {
    const reqTable = Object.assign({}, this.reqFilterTable, {data: this.reqFilterData })
    
    getSchoolListApi(reqTable).then((res: any) => {
      if(res.data.datas&&res.data.datas.length) {
        this.schoolData = res.data.datas;
        this.reqFilterTable = Object.assign(res.data.page)
      }else{
        this.schoolData=[];
        this.reqFilterTable.currentPage = 1;
        this.reqFilterTable.totalResult=0;
      }
    })

  }
  // 设置每页显示n条时触发
  handleSizeChange(pageSize: number) {
    this.reqFilterTable.showCount = pageSize;
    this.reqFilterTable.currentPage = 1;
    this.getSchoolList();
  }
  // 当前页change
  handleCurrentChange(curPage: number) {
    this.reqFilterTable.currentPage = curPage;
    this.getSchoolList();
  }
  // 重置
  reset(){
    this.reqFilterTable.currentPage = 1;
    this.reqFilterTable.showCount = 10;
    this.reqFilterData.keyword = '';
    this.reqFilterData.fkAreaCountryId= '';
    this.getSchoolList();
  }
  // 搜索
  search(){
    this.reqFilterTable.currentPage = 1;
    this.getSchoolList();
  }
  // 新增
  create(){
    (this.$refs.addSchool as any).showDetail = true;
  }
  // 刷新
  refresh(){
    this.getSchoolList();
  }
}
