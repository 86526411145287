var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base_container_main_scroll"},[_c('head-menu-box-with-country',{attrs:{"create-show":_vm.isPermission('institutionAdd'),"keyword":_vm.reqFilterData.keyword,"fkAreaCountryId":_vm.reqFilterData.fkAreaCountryId},on:{"reset":_vm.reset,"search":_vm.search,"update:keyword":function($event){return _vm.$set(_vm.reqFilterData, "keyword", $event)},"update:fkAreaCountryId":function($event){return _vm.$set(_vm.reqFilterData, "fkAreaCountryId", $event)},"update:fk-area-country-id":function($event){return _vm.$set(_vm.reqFilterData, "fkAreaCountryId", $event)},"create":_vm.create,"refresh":_vm.refresh}}),_c('div',{staticClass:"base_container"},[_c('div',{staticClass:"school-list-table"},[_c('table-list',{attrs:{"listData":_vm.schoolData,"headData":[
          { prop: 'institutionName',label: _vm.$t('schoolName'), width: 330,slot: 'institutionName', show: true },
          { prop: 'country',label: _vm.$t('country'), width: 180 },
          { prop: 'studentCount',label: _vm.$t('studentCount'), width: 130 },
          { prop: 'nature',label: _vm.$t('nature'), width: 140, align: 'center'},
          { prop: 'website',label: _vm.$t('website'), width: 330, slot: 'website', show: true },
          { prop: 'pdfSize',label: _vm.$t('pdfSize'), width: 100, align: 'center'}, // 图片个数不算
          { prop: '',label: _vm.$t('opera'), width: 100, fixed:'right', slot:'opera',show: true} ],"reqFilterTable":_vm.reqFilterTable},on:{"handleSizeChange":_vm.handleSizeChange,"handleCurrentChange":_vm.handleCurrentChange},scopedSlots:_vm._u([{key:"institutionName",fn:function(ref){
          var row = ref.row;
return [(_vm.isPermission('institutionView'))?_c('router-link',{staticClass:"pointer",attrs:{"to":{name: 'adminSchoolDetail', params:{id: row.guid}, query:{cus_name: row.name}}}},[_vm._v(" "+_vm._s(row.institutionName)+" ")]):_c('span',[_vm._v(_vm._s(row.institutionName))])]}},{key:"website",fn:function(ref){
          var row = ref.row;
return [_c('a',{staticClass:"pointer",attrs:{"href":row.website,"target":"_blank"}},[_vm._v(_vm._s(row.website))])]}},{key:"opera",fn:function(scope){return [_c('icon-button',{attrs:{"showCheck":true},on:{"handleCheck":function($event){return _vm.handleCheck(scope.row)}}})]}}])})],1)]),_c('add-school',{ref:"addSchool",attrs:{"schoolId":""},on:{"refresh":_vm.getSchoolList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }