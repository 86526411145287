





















import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
@Component({ name: 'HeadMenuBox' })
export default class HeadMenuBox extends Vue {
  @Prop({required: false, type: String}) keyword!: string;
  @Prop() fkAreaCountryId?:any
  @Prop() createShow!: Boolean
  get _keyword(){
    return this.keyword;
  }
  get _fkAreaCountryId(){
    return this.fkAreaCountryId;
  }
  set _keyword(val: string){
    this.$emit('update:keyword',val)
  }
  
  set _fkAreaCountryId(val: any){
    this.$emit('update:fkAreaCountryId',val)
  }
  @Emit()
  search(){
    return [this.keyword,this.fkAreaCountryId]
  }
  @Emit()
  reset(){}
  @Emit()
  create(){}
  @Emit()
  refresh(){}
}
