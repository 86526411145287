




































































import { ReqFilterTable } from '@/interface'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
@Component
export default class TableListVue extends Vue {
  // 例子可以看 BMS学校中心-学校提供商：学校集团管理和学校提供商管理
  // 列表对象
  @Prop({ default: '' }) listData!: any[]
  // table样式
  @Prop({ default: 'min-width: 876px; width: calc(100vw - 80px);' }) listStyle?: string
  // 头部对象 prop:显示的值,label:标题,width:最小宽度,fiexd:固定方向,show:自定义内容的时候是否显示, align: center表示居中
  @Prop({ default: [] }) headData!: any[]
  // 翻页对象
  @Prop({ default: '' }) reqFilterTable!: ReqFilterTable<any>

  // 列表
  dataList: any = []

  @Watch('listData')
  changeData() {
    this.$nextTick(() => {
      if (this.listData) {
        this.dataList = JSON.parse(JSON.stringify(this.listData));
      }
    })
  }
  // 设置每页显示n条时触发
  handleSizeChange(pageSize: number) {
    this.$emit('handleSizeChange', pageSize);
  }
  // 当前页
  handleCurrentChange(curPage: number) {
    this.$emit('handleCurrentChange', curPage);
  }
}
